$whiteBG: #fff;
$blackBG: #0d0d0d;
$blueBG: #56baed;
$buttonBlueBG: #39ace7;
$warningTex: #bf1650;
$backgroundLeft: #abc8e2;
$backgroundRight: #ecf1f1;
$linkColor: #92badd;
$headingColor: #cccccc;
$headingColorInactive: #cccccc;
$headingColorActive: #0d0d0d;
$headingColorBottom: #5fbae9;
$underLineHoverColor: #56baed;

$headerColor: #355165;
$jumbotron-titleColor: #7394af;
$footerColor: #e1e2e1;

// $printBorderColor:rgba(0,0,0,.1);
$printBorderColor:black;