.MuiButtonBase-root {
    box-shadow:none!important;
}

.search-input {
    padding: 0px!important;
}

.search-btn {
    margin: 0px!important;
    margin-left: 2.5rem!important;
    padding: 0.1rem 1.75rem!important;
}