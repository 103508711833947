@import "./colors/common.scss";
@import "./fonts/fonts.scss";
p {
  color: $warningTex;
}

/* p::before {
    display: inline;
    content: "⚠";
  } */

html,
body,
#root{
  @extend .monsterrat-regular;
  // height: 100%;
  min-height: 100vh;
  position: relative
}

#root > div  {
  @extend .monsterrat-regular;
  padding-bottom: 2.5rem;
}

body {
  background: $backgroundLeft;
  background: linear-gradient(to right, $backgroundLeft, $backgroundRight);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* BASIC */

html {
  background: $backgroundLeft;
  background: linear-gradient(to right, $backgroundLeft, $backgroundRight);
}

body {
  height: 100vh;
}

a, .logoutText {
  color: $linkColor;
  display: inline-block;
  text-decoration: none;
  font-weight: 400;
}

.moduleHeaderDropdown{
  margin-top: 8px;
  margin-right: 5px;
}
.dropdown-toggle{
  color: $linkColor !important;
}

a:hover {
  color: $underLineHoverColor;
}

.logoutText:hover {
  text-decoration: underline;
}

h2 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
  margin: 40px 8px 10px 8px;
  color: $headingColor;
}

/* STRUCTURE */

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: calc(100vh - 2.5rem);
  padding: 20px;
}

#formContent {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: $whiteBG;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  padding: 0px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
}

#formFooter {
  background-color: $whiteBG;
  border-top: 1px solid $whiteBG;
  padding: 25px;
  text-align: center;
  -webkit-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}

/* TABS */

h2.inactive {
  color: $headingColorInactive;
}

h2.active {
  color: $headingColorActive;
  border-bottom: 2px solid $headingColorBottom;
}

.modal-header {
  .close {
    box-shadow: none!important;
  }
}

/* FORM TYPOGRAPHY*/

input[type="button"],
input[type="submit"],
input[type="reset"],button {
  background-color: $blueBG;
  border: none;
  color: $whiteBG;
  padding: 15px 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
  box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px 40px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

input[type="button"]:hover,
input[type="submit"]:hover,
input[type="reset"]:hover {
  background-color: $buttonBlueBG;
}

input[type="button"]:active,
input[type="submit"]:active,
input[type="reset"]:active {
  -moz-transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -o-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}

.input-login {
  background-color: #f6f6f6;
}

input[type="text"],
input[type="password"],
input[type="email"] {
  color: $blackBG;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

input[type="text"]:focus,
input[type="password"]:focus {
  background-color: #fff;
  border-bottom: 2px solid #5fbae9;
}

input[type="text"]:placeholder {
  color: #cccccc;
}

/* ANIMATIONS */

/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
}

.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.fadeIn.third {
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.fadeIn.fourth {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: $underLineHoverColor;
  content: "";
  transition: width 0.2s;
}

.underlineHover:hover {
  color: $headingColorActive;
}

.underlineHover:hover:after {
  width: 100%;
}

/* OTHERS */

*:focus {
  outline: none;
}

#icon {
  width: 60%;
}

.header {
  background-color: $headerColor !important;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem; 
  border-radius: 0px !important;
  background-color: $footerColor;
  opacity: 0.5;
}
.footer-content {
  display: flex;
  justify-content: center;
}
.footer-content h4 {
  margin-top: auto;
  margin-bottom: auto;
}

.form-row {
  margin: 20px;
  justify-content: center;
}

.form-group {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.loaderContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  text-align: center;
  backdrop-filter: blur(3px);
}

.loaderInner {
  position: fixed;
  top: 50%;
  left: 50%;
}

.loader {
  color: $headerColor;
}


.company-info-container{
  width: 70%;
  word-wrap: break-word;
}

.company-container{
  display: flex;
  justify-content: center;
}

.filter-container{
  box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.3) !important;
  margin-top: 1% !important;
}