@import "../colors/common.scss";
.challan-consigner-address,.challan-company-address{
    width: 400px;
    word-wrap: break-word;
}

.custom-challan-table {
    thead tr{
        background-color: black;
        color: white;
    }

    tfoot tr {
        background-color: lightgray;
        color: black;
    }
}

.print-flex-main-container {
    position: relative;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .print-center {
    display: flex;
    margin: 1px;
    padding: 5px;
  }
  
  .print-right {
    position: absolute;
    right: 0;
  }
  
  .print-left {
    position: absolute;
    left: 0;
  }