@import "./colors/common.scss";
.main-container {
  margin-top: -3%;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
}

.main-container-body {
  padding: 2.25rem !important;
  text-align: center;
}

.jumbotron-title {
  margin-bottom: 0px !important;
  background-color: $jumbotron-titleColor !important;
  border-radius: 0px !important;
}

.jumbotron-title h4 {
  text-shadow: 0px 10px 25px black;
}

.card {
  border-radius: 10px 10px 10px 10px !important;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3) !important;
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3) !important;
}
