@import "../colors/common.scss";
.form-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
}

.invoiceUploadContainer {
  height: 200px;
  width: 100%;
  border: 1px solid black;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  text-align: center;
}

.App {
  overflow: auto !important;
}

.important {
  color: red;
}

.print-flex-main-container {
  position: relative;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.print-center {
  display: flex;
  margin: 1px;
  padding: 5px;
}

.print-right {
  position: absolute;
  right: 0;
}

.print-left {
  position: absolute;
  left: 0;
}

.display-right-border{
  border-right: 1px solid $printBorderColor;
  height: auto;
}

.print-jobsheet-div hr {
  border-color: $printBorderColor;
}

#auto-complete-list-container {
  position: absolute;
  left: 5%;
  right: 5%;
  top: 100%;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  text-align: left;
  height: auto;
  max-height: 150px;
  overflow-y: scroll;
}

.autocomplete-items option {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
}

.autocomplete-items option:hover {
  background-color: #e9e9e9; 
}